import * as React from "react"
import { Link } from "gatsby"

import Layout from "@components/common/Layout"
import Seo from "@components/common/SEO"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Seo
        title={'ページが見つかりません - Cuddle Place（カドル プレイス）'}
      />
      <div>Not found（ページが見つかりません）</div>
      <Link to="/">Go home</Link>
    </Layout>
  )
}

export default NotFoundPage
